<template>
  <div class=" ">

    <h2 class="f-w-600">
      <strong class="f-w-700">Confirm your email</strong>
    </h2>
    <h5>
      <strong class="f-w-700 text-success my-5" v-text="$route.query.email"> </strong>
    </h5>
    <hr>
    <h5 class="my-4">
      Please check your email to confirm your account.
    </h5>
    <p class="my-4">
      If <strong v-text="$route.query.email"> </strong> is not your email
      address, please
      <router-link to="/accounts/signup" v-text="'go back'"></router-link> and enter
      the correct one.
    </p>
    <hr />
    <p class="my-4">
      If you haven't received our email in 2 minutes, please check your spam folder.
    </p>
    <p>If you still haven't received it,<br> please contact support (<a href="mailto:support@ecart.com"
        target="_blank">support@ecart.com</a>).
      <br>
      <br>
      <b-button variant="link" :to="{ path: '/accounts/login', query: { ...$route.query }}">
        Login
      </b-button>
    </p>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>